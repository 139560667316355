.container-style {
    height: 100vh;
    background: radial-gradient(circle, #1f4224, #051b08);
  }
  
  #tsparticles { 
    height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 0;
  }

  .HeroContent {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 60vw;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-size: 20px;
    color: white;
    padding-top: 5vh;
  }

  /* Style for the content on the right */
  .content {
    width: 20vw;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    padding-bottom: 50px;
  
  }
  
  /* Style for the iPhone image */
  .iPhoneImage {
    height: 32vw;
    width: 16vw;
    z-index: 2;
    border-radius: 2.4vw;
    border: 1px solid rgba(80, 80, 80, 0.6);

    box-shadow: 0px 5px 40px rgb(0, 0, 0);
  }

  .welcome-message {
    color: rgb(218, 218, 218);
    font-family: 'controller-font', 'Arial';
    text-align: center;
    font-size: 1.1vw;
    line-height: 1.5vw;
    margin-bottom: 2vw;
  }