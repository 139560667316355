.mobilefooter-container {
    background: black;
}

.mobile-disclaimer {
    padding-top: 3vw;
    margin: 0;
    color: gray;
    font-size: 2vw;
    text-align: center;
}

.mobile-link-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.mobile-social-links {
    color: white;
    padding: 0px;
    margin: 0px;
    text-decoration: underline;
}

 /* right-buttons */
 .mobile-social-links {
    padding-top: 5px;
    display: flex;
    justify-content: center;
  }
  
  /* Style for individual icon links */
  .mobile-social-links a {
    margin-right: 20px; /* Adjust the spacing between icons */
    margin-left: 20px;
    color: white; /* Set the color to green */
    
  }
  
  /* size of social icons */
  .mobile-social-links svg {
    width: 20px; 
    height: 20px;
    padding-bottom: 4vh;
  }