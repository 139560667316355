.TOS-PP-container {
    padding-top: 100px;
    margin-bottom: 50px;
}

.content-container {
    display: flex;
    flex-direction: column;
}

/* Title */
.content-container h1 {
    font-family: 'controller-font', 'helvetica';
    margin: 0;
    color: rgb(233, 233, 233);
    text-align: center;
}

/* Last Updated Date */
.content-container h3 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: 'controller-font', 'helvetica';
    color: rgb(226, 226, 226);
    text-align: center;
}

.content-container h2 {
    color: rgb(228, 228, 228);
    margin: 0;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 0vh;
    padding-bottom: 5px;
}

.content-container p {
    color: rgb(228, 228, 228);
    margin: 0;
    margin-left: 22%;
    margin-right: 22%;
    padding-top: 0vh;
    padding-bottom: 5px;
}
