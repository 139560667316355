/* Custom styles for the AwesomeSlider */
/* Override default slider styles */

/* Loading bar color */
.awssld__bar {
    --loader-bar-color: rgb(21, 107, 61) !important; /* Change this to your desired color */
}

/* Chevron (navigation arrows) color */
.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
    --organic-arrow-color: lightgray !important; /* Change this to your desired color */
}

/* Dots (pagination circles) color */
.awssld__bullets button {
    background-color: lightgray !important;
    height: 10px;
    width: 10px;
    margin-left: 20px;
    margin-right: 5px;
}

.awssld {
    overflow: visible !important; /* Ensure overflow is hidden */
}

.awssld__next,
.awssld__prev {
    width: 50px; /* Adjust the width to your preference */
    height: 50px; /* Adjust the height to your preference */
    
    transform: translateY(-15%); /* Center vertically */
    z-index: 1; /* Ensure they are above other elements */
}

.awssld__next {
    right: 3vw; /* Position 10px from the right edge of the slider */
}

.awssld__prev {
    left: 3vw; /* Position 10px from the left edge of the slider */
}
