.mobile-contact-section {
  background-color: rgb(22, 22, 22);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-contact-entries {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mobile-team-picture {
  width: 100px;
  border-radius: 50px;
  margin-top: 20px;
}

.mobile-category {
  background-color: rgb(36, 36, 36);
  text-align: center;
  align-items: center;
  width: 65%; /* Each category takes up one-third of the screen width */
  min-width: 210px;
  max-width: 260px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.8);
  margin-top: 15px;
  margin-bottom: 10px;
}

.mobile-category h2 { /* Name */
  color: rgb(221, 221, 221);
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 0;
}

.mobile-category h3 { /* Title */
  font-size: 14px;
  color: rgb(180, 180, 180);
  margin-top: 3px;
  margin-bottom: 0;
}
.mobile-category p { /* email */
  text-decoration: none;
  color: rgb(180, 180, 180);
  font-size: 12px; 
  margin-top: 3px;
  margin-bottom: 10px;
}

.mobile-general-inq {
  color: rgb(120,120,120);
  font-size: 3.5vw;
  margin-top: 30px;
  padding-bottom: 5vw;
}

.mobile-linkedin-logo {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100px;
  height: 35px;
  border-radius: 5px;
}