.mobile-about-section {
  background: linear-gradient(#102e2b, #0e5541);
  text-align: center;
  height: 140vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-websitegraphic {
  width: 99%;
}

.mobile-about-content {
  width: 90%;
  color: rgb(218, 218, 218);
  font-family: 'controller-font', 'Arial';
  font-size: 4vw;
  font-weight: 100vw;
  line-height: 6vw;
  margin-top: 20px;
}