.about-section {
    background: linear-gradient(#102e2b, #0e5541);
    min-height: 700px;
    padding-left: 8%;
    padding-right: 8%;
    display: flex;
    align-items: center;
    font-family: 'controller-font', 'Helvetica';
}

/* mission statemet */
.mission-container {
    flex: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 80px;
}

.about-title-image {
  padding: 20px;
  background-color: rgba(68, 68, 68, 0.04);
  width: 280px;
  margin-top: 4vh;
}

/* mission statement */
.about-content {
  font-family: 'controller-font', 'Times New Roman'; 
  color: rgb(218, 218, 218);
  width: 80%;
  font-size: 2.4vh; /* Responsive font size */
  line-height: 3.1vh; /* Adjust line height to match font size */
  margin-top: 2vh;
}


/* graphic container */
.graphic-container {
  flex: 11;
}

.graphic-img {
  width: 100%;
}