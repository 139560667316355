  
  .header-content {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* Style for the left-buttons container */
.left-buttons {
    padding-left: 30px;
    display: flex;
    align-items: center;
  }

  .route-links {
    text-decoration: none;
    background: none;
    color: rgb(232, 232, 232);
    border: none;
    padding: 0;
    font-family: 'controller-font', 'Arial';
    font-size: 18px;
    margin-right: 30px;
    transition: color 0.3s;
  }
  
  .route-links:hover {
    color: #48D16F;
    cursor: pointer;
  }
  

  /* right-buttons */
.right-buttons {
    display: flex;
  }
  
  /* Style for individual icon links */
  .right-buttons a {
    margin-right: 40px; /* Adjust the spacing between icons */
    color: rgb(218, 218, 218);
    transition: color 0.3s;
  }
  
  /* size of social icons */
  .right-buttons svg {
    width: 30px; 
    height: 30px;
  }

  .right-buttons a:hover {
    color: #48D16F;
  }

  .logo {
    width: 50px; /* Adjust the max-width of your logo */
    height: 50px;
    margin-right: 4vw;
  }

  .dividing-line { /* NOT IN USE - bottom border gradient */
    height: 1px; 
    background: linear-gradient(to right, #1B4B48, #48D16F, #1B4B48);
  }