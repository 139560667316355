.DiscordButton-container {
    background: #738ADB;
    width: 13vw;
    transition: color 0.2s;
    transition: padding-left 0.5s, padding-right 0.5s, padding-top 0.8s, padding-bottom 0.8s;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.3);
    padding: 0.7vw 0.3vw;
    text-decoration: none;
    box-shadow: -2px -2px 4px rgba(209, 209, 209, 0.5), 2px 2px 5px rgba(0, 0, 0, 0.9);
  }
  
.DiscordButton-container:hover {
    background: #4e64b4;
    padding: 0.7vw 0.6vw;
}

.DiscordButton-items {
    display: flex;
    gap: 0.4vw;
    align-items: center;
    justify-content: center;
    color: white;
}

.discord-button {
    width: 2vw;
    height: 2vw;
}

.button-text {
    margin: 0;
    font-family: 'controller-font', 'helvetica';
    font-size: 0.8vw;
}