.App {
  
}

Body {
    background-color: #0e5541;
    /*background: linear-gradient(#1B4B48, #072B21);*/
}

.no-scroll {
    overflow: hidden;
    height: 100%;
}