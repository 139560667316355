.footer-container {
    background: black;
}

.disclaimer {
    margin: 0;
    padding-top: 10px;
    color: gray;
    font-size: 12px;
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: center;
}

.link-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.links {
    color: white;
    padding: 15px;
    text-decoration: underline;
}
