.mobile-features-container {
    height: 144vw;
    background-color: rgba(0,0,0,0.3);
    overflow-x: scroll;
    display: flex;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}

.image-slider {
    display: flex;
    padding-top: 4vw;
    padding-bottom: 4vw;
    padding-left: 10vw;
    padding-right: 4vw;
    gap: 20px;
    width: 360vw;
    height: 136vw;
}

.slider-image-container {
    position: relative;
}

.slider-image {
    position: relative;
    width: 68vw;
    height: 136vw;
    scroll-snap-align: start;
    flex-shrink: 0;
}


