.mobile-container-style {
    height: 180vw;
    background: radial-gradient(circle, #1f4224, #051b08);
  }
  
  #tsparticlesmobile { 
    height: 185vw;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 0;
  }

  .mobile-hero-content {
    height: 180vw;
    padding-top: 20%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    font-size: 20px;
    color: white;
  }

  
  /* Style for the iPhone image */
  .mobile-iPhoneImage {
    height: 100vw;
    width: 50vw;
    z-index: 2;
    border-radius: 7vw;
    border: 1px solid rgba(80, 80, 80, 0.6);
    box-shadow: 0px 5px 40px rgb(0, 0, 0);
  }

  .mobile-welcome-message {
    width: 85%;
    color: rgb(218, 218, 218);
    font-family: 'controller-font', 'Arial';
    text-align: center;
    font-size: 3.8vw;
    line-height: 4vw;
    margin-top: 6vw;
    margin-bottom: 4vw;
  }