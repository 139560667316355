  .mobile-header-items {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .mobile-menu-button{
    padding-left: 2%;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .mobile-dividing-line {
    position: relative;
    height: 1px;
    background: linear-gradient(to right, #1B4B48, #48D16F, #1B4B48);
  }