.MobileDiscordButton-container {
    background: #738ADB;
    width: 40vw;
    padding: 2vw 3vw;
    text-decoration: none;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    text-decoration: none;
    box-shadow: -2px -2px 5px rgba(255, 255, 255, 0.2), 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.mobile-db-items {
    display: flex;
    gap: 2vw;
    align-items: center;
    justify-content: center;
    color: white;
}

.mobile-discord-button {
    width: 8vw;
    height: 8vw;
}

.mobile-db-text {
    margin: 0;
    font-family: 'controller-font', 'helvetica';
    font-size: 2.6vw;
}

