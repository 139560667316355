.contact-section {
    background-color: rgb(22, 22, 22);
    min-height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-entries {
  margin-top: 20px;
  margin-right: none;
  margin-left: none;
  display: flex;
  align-items: center;
  max-width: 1200px;
}

.category {
  background-color: rgb(36, 36, 36);
  text-align: center;
  width: 280px; /* Each category takes up one-third of the screen width */
  height: 400px;
  margin-left: 1vw;
  margin-right: 1vw;
  padding-top: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.8);

}

.contact-picture {
  width: 130px;
  border-radius: 65px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.category h2 { /* Name */
  color: rgb(221, 221, 221);
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 0px;

}

.category h3 { /* Title */
  font-size: 15px; 
  color: rgb(180, 180, 180);
  margin-top: 5px;
  margin-bottom: 0;
}

.category p { /* email */
  font-size: 14px;
  color: rgb(180, 180, 180); 
  margin-top: 5px;
}

.linkedin-logo {
  margin-top: 25px;
  width: 100px;
  height: 35px;
  border-radius: 5px;
}

.general-inq-statement {
  padding-top: 100px;
  padding-bottom: 40px;
  font-size: 16px;
  color: rgb(150, 150, 150); 
}

  