.modal-content {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999; /* Ensure it's on top of other elements */
    background: radial-gradient(circle, #0e5541,#102e2b);
}

.modal-overlay {
    background-color: rgba(172, 172, 172); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1000;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
}

.modal-logo {
    width: 10vw;
    height: 10vw;
    padding: 10px;
    animation: fadeIn 0.5s ease-in-out;
}


  .modal-items {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .modal-home-about {
    animation: fadeIn 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 2vw;
    align-items: center;
    padding-top: 10vh;
  }

  .modal-links {
    font-family: 'controller-font', 'helvetica';
    text-decoration: none;
    background: none;
    color: rgb(218, 218, 218);
    border: none;
    padding-bottom: 4vh; /* Remove any padding or spacing */
    font-size: 8vw;
  }

  .modal-socials {
    animation: fadeIn 0.8s ease-in-out;
    padding-top: 16vh;
    padding-left: 5%;
  }

  .modal-socials a {
    margin-right: 35px; /* Adjust the spacing between icons */
    color: #48D16F; /* Set the color to green */ 
  }

  .modal-social-icon {
    width: 10vw;
    height: 10vw;
  }